// 
// _card.scss
// 

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: var(--#{$prefix}box-shadow);
    border: none;
}

.card-drop {
    color: var(--#{$prefix}secondary-color);
}

.card-title {
    font-size: 16px;
    // color: var(--#{$prefix}card-title-color) !important;
}

.card-title-desc {
    margin-bottom: 0;
}

.best-product-title{
    color: $dark;
}

.card-h-100 {
    height: calc(100% - #{$grid-gutter-width});
}

@mixin card-header-variant($bg) {
    background-color: lighten($bg, 5%) !important;
    border-bottom: none
}

@each $color,
$value in $theme-colors {
    .card-header.bg-#{$color} {
        @include card-header-variant($value);
    }
}


// body[data-layout-mode="dark"] {

//     .card{
//         box-shadow: 0 2px 3px darken($gray-dark-200, 4%);
//     }

//     .card, .card-header, .card-footer,
//     .modal-content, .offcanvas {
//         background-color: $gray-dark-200;
//         border-color: lighten($gray-dark-200,4%);
//     }

//     .card-title-desc{
//         color: $gray-dark-400;
//     }
// }